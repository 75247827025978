import RetailerConfig from "./retailer";
import queryString from "query-string";


const hostname = window.location.hostname;
// const isStaging = hostname.includes('cloudfront');
const isLocalhost = hostname.includes('localhost');
// const useTest = isLocalhost || isStaging;
// const sub = useTest ? "test." : "";

const parsed = queryString.parse(window.location.search);
const config = {
  myEnv: isLocalhost ? 'local' : 'production',
  retailer: RetailerConfig,
  baseUrl: parsed.testing ? "" : "https://ice.dpn.inmar.com/v2/",
  // removing to only show prod offers on CF urls
  // baseUrl: parsed.testing ? "" : "https://ice." + sub + "dpn.inmar.com/v2/",
  originUrl: isLocalhost ? `${window.location.origin}/` : "",
  urlParam: parsed
};
export default Object.freeze(config);
